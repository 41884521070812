<template>
  <div class="container">
     <div class="header">
         <el-image class="img" fit="contain" src="./local.png"></el-image>
         <div class="username">{{name}}</div>
     </div>
     <div class="logOut" @click="logOut">退出登录</div>
  </div>
</template>
<script>
import {jump} from "@/utils/index"
import {getCookie} from "@/utils/cookie"
export default {
  data() {
    return {
      name:getCookie('name'),
   
    };
  },
  created(){
    console.log(location);
  },
  
  beforeCreate() {
    document.querySelector("body").style.background = "#fff";
 
  },
  methods:{
    logOut(){
      this.$store.dispatch("resetToken");
      location.reload()
    }
  }
};
</script>

<style lang="scss" scoped>
$height:.37037rem;
 .container{
   background: #fff;
   height: calc(100vh - 2px);
 
   border-top:.185185rem /* 10/54 */ solid #EDF3FC;
   padding-top:.37037rem /* 20/54 */;
   .header{
     text-align: center;
     margin-top: 2rem;
     .img{
       height: 1.481481rem /* 80/54 */;
       width: 1.481481rem /* 80/54 */;
    
     }
   }
   .logOut{
     background: #FF1A00;
     color:#fff;
     width: 3.148148rem /* 170/54 */;
     border-radius: .555556rem /* 30/54 */;
     text-align: center;
     line-height: .925926rem /* 50/54 */;
     margin: auto;
     margin-top:5rem /* 500/54 */;

   }
 }
</style>
